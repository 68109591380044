<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-8 is-offset-2">
          <b-table :data="fastCommentInfo.studentsAffected" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{
                fastCommentInfo.studentsAffected.indexOf(props.row) + 1
              }}</b-table-column
            >

            <b-table-column label="Name" v-slot="props">{{
              props.row.name
            }}</b-table-column>

            <b-table-column label="Total" v-slot="props">
              {{ props.row.total }}
            </b-table-column>

            <b-table-column label="Average" v-slot="props">{{
              props.row.average
            }}</b-table-column>
          </b-table>
        </div>
        <div class="column is-8 is-offset-2">
          <button
            type="submit"
            class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
            @click="initializeFastComment()"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="apply-fast-comment"
      :modal-title="pageTitle"
      context="update"
      @update="apply"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add">
        <p>Do you want to apply this comment?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'Fast Comment Info',
      fastCommentInfo: [],
      user: null,
    }
  },
  methods: {
    initializeFastComment() {
      this.openModal()
    },
    apply() {
      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ApplyFastCommentQuery(
              $commentId: Int!
              $userId: Int!
              $students: JSON!
            ) {
              applyFastComment(
                input: {
                  commentId: $commentId
                  userId: $userId
                  students: $students
                }
              ) {
                errors
              }
            }
          `,
          // Parameters
          variables: {
            commentId: parseInt(this.fastCommentInfo.commentId),
            userId: parseInt(this.user.id),
            students: this.fastCommentInfo.studentsAffected.map(
              (item) => item.student_id
            ),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.applyFastComment.errors,
            'Successfully applied.'
          ).then(() => {
            this.closeModal()
            this.$router.push(
              `/school/${this.$route.params.school_id}/fast_comments`
            )
          })
        })
        .catch((error) => {
          console.error(error.message)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$store.commit('setPageTitle', this.pageTitle)

    this.$apollo.addSmartQuery('fastCommentInfo', {
      query: gql`
        query FastCommentInfoQuery($id: ID!) {
          fastCommentInfo(id: $id) {
            commentId
            studentsAffected
          }
        }
      `,
      variables: {
        id: parseInt(this.$route.params.id),
      },
    })

    this.$apollo.queries.fastCommentInfo.refetch()

    this.$store.commit('setSubMenus', [
      {
        name: 'Fast Comments',
        route: `/school/${this.schoolId}/fast_comments`,
      },
    ])

    fetchUser().then((user) => {
      this.user = user
    })
  },
}
</script>
